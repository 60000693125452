<template>
  <div>
    <el-container>
      <CommonTree
        :treeData="treeData"
        :defaultProps="defaultProps"
        :searchTitle="searchTitle"
        :isShowdig="true"
        :showCheckbox="false"
        :popover-show="true"
        :treeTitle="$t(`cip.plat.sys.knowledge.title.treeTitle`)"
        @getTreeAdd="treeMenuAdd"
        @getTreeEdit="treeMenuEdit"
        @getTreeDelete="treeNodeDel"
        @getNodeClick="treeNodeClick"
        @include-down="includeDown"
      />
      <el-main>
        <head-layout
          :head-btn-options="headBtnOptions"
          :head-title="$t(`cip.plat.sys.knowledge.title.indexHeadTitle`)"
          @head-remove="headRemove"
          @head-add-tabs="headAdd"
        ></head-layout>
          <grid-head-layout
            ref="gridHeadLayout"
            :grid-head-btn="gridHeadBtn"
            :search-columns="searchColumns"
            @grid-head-search="gridHeadSearch"
            @grid-head-empty="gridHeadEmpty"
          ></grid-head-layout>
        <grid-layout
          ref="gridLayOut"
          :grid-row-btn="gridRowBtn"
          :table-options="tableOptions"
          :table-data="tableData"
          :table-loading="tableLoading"
          :data-total="page.total"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          @page-refresh-change="onLoad"
          @grid-row-detail-click="rowDetail"
          @row-remove="rowRemove"
          @row-edit="rowEdit"
        ></grid-layout>
      </el-main>
    </el-container>

    <el-dialog
      v-dialog-drag
      :title="$t(`cip.plat.sys.knowledge.title.treeHeadCateTitle`)"
      :modal="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showTreeDialog"
      v-if="showTreeDialog"
      width="60%"
      :fullscreen="fullscreen"
      class="qmDialog"
    >
      <div class="positionabs">
        <i class="el-icon-full-screen" @click="fullClick()"></i>
      </div>
      <avue-form :option="treeDialogOption" ref="addForm" v-model="form" @submit="treeNodeSave"></avue-form>
      <span slot="footer">
        <el-button size="small" @click="showTreeDialog = false"
        >{{ $t(`cip.cmn.btn.celBtn`) }}</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="handleTreeNodeSave"
        >{{ $t(`cip.cmn.btn.defBtn`) }}</el-button
        >
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {remove as removeTree, saveOrUpdate as saveOrUpdateTree, tree} from '@/api/system/knowledgeCate'
import {page as getList, remove} from "@/api/system/knowledge";

import CommonTree from "@/views/components/com_tree/index"
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import TreeLayout from "@/views/components/layout/tree-layout";

import ServerNameEnum from '@/util/ServerNameEnum';

const CONTROLLER_NAME = "knowledgeCate";

export default {
  name: "knowledge",
  data() {
    return {
      form: {
        parentId: '',
      },
      treeReadOnlyFlag: false,
      extItemCodeFlag: true,
      tableData: [],
      drawerBox: false,
      drawerTitle: "",
      showItem: "",
      itemId: -1,
      bsItemGategory: {},
      treeLoading: false,
      tableLoading: false,
      crudLoading: false,
      treeData: [],
      crudData: [],
      selectionList: [],
      query: {},
      knowledgeTypeId: '', // 点击左侧树的code
      node: {},   //左侧树node
      fullscreen: false,
      showTreeDialog: false,
      searchTitle: 'knowledgeCategoryName',
      defaultProps: {
        label: "knowledgeCategoryName",
        value: "knowledgeTypeId",
        key: "knowledgeTypeId"
      },
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      dataTotal: 0
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.permission.knowledge_add) {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-add-tabs",
            type: "button",
            icon: "",
            btnOptType: 'add',
          });
      }
      if (this.permission.knowledge_delete) {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "head-remove",
            type: "button",
            icon: "",
            btnOptType: 'remove',
          });
      }

      return buttonBtn;
    },
    tableOptions() {
      return {
        linklabel: "knowledgeCode",
        column: [
          {
            label: this.$t('cip.plat.sys.knowledge.field.knowledgeCode'),
            prop: "knowledgeCode",
            search: true,
            overHidden: true,
            width: 500,
            align:'left',
            rules: [
              {
                required: true,
                message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sys.knowledge.field.knowledgeCode'),
                trigger: "blur"
              }
            ]
          },
          {
            label: this.$t('cip.plat.sys.knowledge.field.knowledgeName'),
            prop: "knowledgeName",
            search: true,
            overHidden: true,
            align:'left',
            rules: [
              {
                required: true,
                message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.sys.knowledge.field.knowledgeName'),
                trigger: "blur"
              }
            ]
          },
        ]
      }
    },
    gridHeadBtn() {
      return []
    },
    searchColumns() {
      return [
        {
          label: "",
          prop: "knowledgeCode",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.plat.sys.knowledge.field.knowledgeCode`)
        },
        {
          label: "",
          prop: "knowledgeName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.plat.sys.knowledge.field.knowledgeName`),
        },
      ]
    },
    gridRowBtn() {
      let buttonBtn = [];
      if (this.permission.knowledge_edit) {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.editBtn`),
            emit: "row-edit",
            type: "button",
            icon: ""
          });
      }
      if (this.permission.knowledge_delete) {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "row-remove",
            type: "button",
            icon: ""
          })
      }
      return buttonBtn;
    },
    treeOptions() {
      return {
        title: this.$t('cip.plat.sys.knowledge.title.treeHeadCateTitle'),
        defaultExpandAll: true,
        nodeKey: 'id',
        size: 'mini',
        menu: true,
        lazy: false,
        dialogType: 'drawer',
        addBtn: false,
        editBtn: false,
        delBtn: false,
        props: {
          labelText: this.$t("cip.plat.sys.knowledge.title.treeHeadCateTitle"),
          id: "id",
          label: 'knowledgeTypeId',
          value: 'id',
          children: 'children'
        },
      };
    },
    treeDialogOption() {
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [
          {
            label: this.$t("cip.plat.sys.knowledge.field.parentCode"),
            prop: "parentId",
            type: "tree",
            overHidden: true,
            width: 140,
            align:'left',
            props: {
              label: 'knowledgeCategoryName',
              value: 'id',
              key: 'parentId',
            },
            placeholder: this.$t(`cip.plat.sys.knowledge.field.parentCode`),
            dicUrl: `${ServerNameEnum.SERVER_SYSTEM_}/${CONTROLLER_NAME}/tree`,
          },
          {
            label: this.$t(`cip.plat.sys.knowledge.field.knowledgeTypeCode`),
            prop: "knowledgeCategoryCode",
            maxlength: 20,
            disabled: this.treeReadOnlyFlag,
            overHidden: true,
            width: 140,
            align:'left',
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.plat.sys.knowledge.field.knowledgeTypeCode`),
                trigger: ["blur", "change", 'click'],
              },
            ],
          },
          {
            label: this.$t(`cip.plat.sys.knowledge.field.knowledgeTypeName`),
            prop: "knowledgeCategoryName",
            maxlength: 20,
            showWordLimit: true,
            overHidden: true,
            width: 140,
            align:'left',
            rules: [
              {
                required: true,
                message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.plat.sys.knowledge.field.knowledgeTypeName`),
                trigger: ["blur", "change", 'click'],
              },
            ],
          },
          {
            label: this.$t(`cip.plat.sys.knowledge.field.remark`),
            prop: "remark",
            overHidden: true,
            width: 140,
            align:'left',
            type: "textarea",
            span: 24,
            minRows: 2,
            maxRows: 2,
            maxlength: 200,
            showWordLimit: true,
          },
        ]
      }
    },


  },
  components: {
    GridLayout,
    HeadLayout,
    TreeLayout,
    CommonTree
  },
  created() {
    tree()
      .then((result) => {
        this.treeData = result.data.data;
        this.treeLoading = false;
      })
      .catch((err) => {
        this.treeLoading = true;
      });
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {

    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    // 左侧树新增
    treeMenuAdd() {
      this.form = {};
      this.form.parentId = this.node.id;
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
    },

    // 左侧树编辑
    treeMenuEdit(data) {
      this.treeReadOnlyFlag = true;
      this.fullscreen = false;
      this.showTreeDialog = true;
      this.form = data;
    },

    initTree() {
      tree({parentId: 0}).then(res => {
        const {data} = res.data;
        this.treeData = data;
      });
    },
    // 新增左侧树节点
    handleTreeNodeSave() {
      this.$refs.addForm.submit();
    },
    // 新增左侧树节点
    treeNodeSave(data, done) {
      this.form.knowledgeCategoryCode = this.form.knowledgeCategoryCode.trim()
      this.form.knowledgeCategoryName = this.form.knowledgeCategoryName.trim()
      this.$refs.addForm.validate(valid => {
        if (valid) {
          saveOrUpdateTree(this.form).then(res => {
            const {msg, data} = res.data;
            this.treeReadOnlyFlag = true;
            if (res.data.code == 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              this.$message.success(msg);
            }
            this.initTree();
            this.showTreeDialog = false;
          }).catch(err => {
            done()
          })
        }
      })
    },
    // 删除左侧树节点
    treeNodeDel(data, done) {
      if (data.hasChildren) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.hasChildrenWarning'))
        return
      }

      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      }).then(() => {
        removeTree(data.id).then(res => {
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
          this.initTree()
          done()
        })
      }).catch(() => {
      });
    },

    // 点击左侧树节点
    treeNodeClick(node) {
      this.node = node;
      const {id} = node;
      this.knowledgeTypeId = id;
      this.page.total = 0;
      this.onLoad(this.page);
    },

    includeDown(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },

    headRemove() {
      let selectionList = this.$refs.gridLayOut.selectionList
      let ids = selectionList.filter(e => e.id).map(e => e.id)
      if (selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          if (ids.length === 0) {
            //只选中了空数据
            let index = 0;
            selectionList.filter(e => !e.id).forEach(e => {
              this.$refs.gridLayOut.tableData.splice(e.$index - index, 1);
              index++
            })
            this.$refs.gridLayOut.selectionClear()
            return;
          }
          return remove(this.$func.join(ids))
            .then(() => {
              this.onLoad(this.page, {});

              this.$message({
                type: "success",
                message: this.$t('cip.cmn.msg.success.operateSuccess')
              });
              this.$refs.gridLayOut.selectionClear()
            });
        }).catch(() => {
      })
    },

    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page, {});
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },

    rowDetail(row) {
      this.$router.push({
        path: `/knowledge/knowledgeEdit/view`,
        query: {
          id: row.id,
          pageType: 'detail'
        }
      })
    },

    rowEdit(row) {
      this.$router.push({
        path: `/knowledge/knowledgeEdit/edit`,
        query: {
          id: row.id,
          pageType: 'edit'
        }
      })
    },

    // 新增
    headAdd() {
      if (!this.knowledgeTypeId) {
        this.$message.warning(this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.sys.knowledge.title.treeTitle'));
        return;
      }
      this.$router.push({
        path: `/knowledge/knowledgeEdit/add`,
        query: {
          pageType: 'add',
          node: this.node
        }
      })
    },

    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.knowledgeTypeId = "";
      this.onLoad(this.page, query)
    },

    close() {
      this.drawerBox = false;
      this.page.currentPage = 1;
      this.onLoad(this.page, {});
    },
    rowSelection(row) {
      this.$refs.crud.toggleRowSelection(row);
    },
    saveHandler() {
      this.drawerBox = true;
      this.drawerTitle = "添加";
      this.showItem = "add";
    },
    selectionClear() {
      this.selectionList = [];
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.crudLoading = true;
      if (this.knowledgeTypeId) {
        params.knowledgeTypeId = this.knowledgeTypeId
      }
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query),
      )
        .then((res) => {
          const data = res.data.data;
          this.$refs.gridLayOut.page.total = data.total;
          this.tableData = data.records;
          this.crudLoading = false;
          this.selectionClear();
        })
        .catch(() => {
          this.crudLoading = true;
        });
    },
    searchChange() {
      const extItemCatId = this.query.extItemCatId;
      this.query = {
        keyword: this.keyword,
      };
      this.query.extItemCatId = extItemCatId;
      this.page.currentPage = 1;
      this.onLoad(this.page, {});
    },
    searchReset() {
      const extItemCatId = this.query.extItemCatId;
      this.query = {};
      this.query.extItemCatId = extItemCatId;
      this.page.currentPage = 1;
      this.onLoad(this.page, {});
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page);
    },
  },
};
</script>

